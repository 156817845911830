<template>
  <div class="app container-md">
<!--    <NavHeader />-->
    <router-view></router-view>
<!--    <right-menu></right-menu>-->
<!--    <NavFooter />-->
  </div>
</template>

<script>
import NavHeader from "@@/components/NavHeader.vue";
import NavFooter from "@@/components/NavFooter.vue";
import rightMenu from "@@/components/rightMenu.vue";

export default {
  components: {
    NavHeader,
    NavFooter,
    rightMenu,
  },
  created(){
  },
  watch:{
    '$route':{
        immediate:true,
        handler(n){
            if(n.path=='/'){
                this.$router.push('/home')
            }
            sessionStorage.setItem('menu',n.path)
        }
    }
  }
};
</script>